<template>
  <collapse-card
    v-bind="$attrs"
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Social Review
      </span>
    </template>
    <template #cardContent>
      <div class="pb-4 py-2 px-4">
        <label class="text-black text-sm">
          Please give us at least 5 business days to review content and 7 business days to review social media influencers.
        </label>
      </div>
      <fieldset :disabled="disableSocialReviewFieldState === VIEW_ONLY">
        <div class="py-2 px-4 flex justify-between items-center">
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socCopyrightCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  <p>Ensure the Marvel legal line © 2023 MARVEL (note spaces and uppercase) has been placed clearly on all images and videos.</p>
                  <p><strong> NOTE: </strong>The year in legal line should reflect current year.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socCopyrightCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
          <span
            v-if="['NEW', 'DRAFT'].includes(submissionFormMode)"
            class="mb-4"
          >
            <base-button
              class="mb-2"
              variant="btn-link"
              text="Copy Template"
              tag="div"
              @click="copyTemplateBtnClicked()"
            />
            <base-button
              variant="btn-link"
              text="Influencer Template"
              tag="div"
              @click="influencerTemplateBtnClicked()"
            />
          </span>
        </div>
        <div class="py-2 px-4">
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socCharCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  <p>Ensure character names and brand vocabulary is spelled according to brand guidelines: <br>Super Hero,
                    Super Villain, Spider-Man, Spider-Sense, Iron Man, Doctor Strange, Daredevil. </p>
                </label>
                <small
                  v-if="submissionFormValidations.socCharCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div class="py-2 px-4">
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socLogoCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <label class="ml-2 text-black text-sm">
                  <p>Ensure the Marvel logo has not been manipulated, truncated, or changed in any way on your
                    images and videos. Do not put the Marvel logo on a red background.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socLogoCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div class="py-2 px-4">
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socSpellCheck"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label class="ml-2 text-black text-sm">
                  <p>Ensure you have checked all spelling and grammar prior to submission.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socSpellCheck"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div class="py-2 px-4">
          <div class="mb-4">
            <base-checkbox
              v-model="submissionForm.socEvergreenContent"
              container-class="flex flex-wrap items-center text-black text-sm"
              value="Y"
              unchecked-value="N"
            >
              <template #label>
                <!-- <span class="text-primary-red ml-4">*</span> -->
                <label class="ml-2 text-black text-sm">
                  <p>Ensure you are not mixing Studios with evergreen/non-Studios content.</p>
                </label>
                <small
                  v-if="submissionFormValidations.socEvergreenContent"
                  class="mb-2 text-red-600 w-full"
                >
                  Must Accept
                </small>
              </template>
            </base-checkbox>
          </div>
        </div>
        <div class="py-2 px-4 grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-date-picker
            v-model="submissionForm.socApprovalDate"
            mode="date"
            container-class="flex text-sm"
            label-class="label-w-46"
            label="Need Approval By:"
            :is-today-visible="false"
            :error="submissionFormValidations.socApprovalDate"
          />
        </div>
        <div class="py-2 px-4 grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-select
            v-model="submissionForm.socPostedEng"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Will this copy be posted in English ?"
            label-class="label-w-46"
            :error="submissionFormValidations.socPostedEng"
            :options="yesNoOptions"
            show-default-option
          />
          <base-select
            v-model="submissionForm.socPostedPhoto"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Will this copy be posted alongside photo or video or assets ?"
            :error="submissionFormValidations.socPostedPhoto"
            label-class="label-w-46"
            :options="yesNoOptions"
            show-default-option
          />
        </div>
        <div class="py-2 px-4 grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-select
            v-model="submissionForm.socReviewInfluencers"
            class="flex text-sm text-left"
            placeholder="-- SELECT --"
            label="Are you submitting influencers to be reviewed ?"
            label-class="label-w-46"
            :error="submissionFormValidations.socReviewInfluencers"
            :options="yesNoOptions"
            show-default-option
          />
        </div>
      </fieldset>
    </template>
  </collapse-card>
</template>

<script>
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import useSubmissionForm from './submissionForm.js';
import { SOCIAL_MEDIA_POST_METARIAL, LICENSEE_STEP, EDITABLE, VIEW_ONLY } from '@/constants/submissions.js';
import { REQUIRED_FIELD } from '@/constants/validationMessages.js';
import { BA_ADMIN_ROLE } from '@/constants/ba-roles.js';
import useUserAuth from '@/hooks/userAuth.js';

export default {
    name: 'SocialReviewDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseCheckbox: defineAsyncComponent(() => import('@/components/generic-components/BaseCheckbox.vue')),
        BaseDatePicker: defineAsyncComponent(() => import('@/components/generic-components/BaseDatePicker.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    inheritAttrs: false,

    setup () {
        const { submissionForm, submissionFormValidations, submissionFormMode } = useSubmissionForm();

        const validateSocialFields = () => {
            submissionFormValidations.socCopyrightCheck = submissionForm.socCopyrightCheck !== 'Y' ? REQUIRED_FIELD : '';
            submissionFormValidations.socCharCheck = submissionForm.socCharCheck !== 'Y' ? REQUIRED_FIELD : '';
            submissionFormValidations.socLogoCheck = submissionForm.socLogoCheck !== 'Y' ? REQUIRED_FIELD : '';
            submissionFormValidations.socSpellCheck = submissionForm.socSpellCheck !== 'Y' ? REQUIRED_FIELD : '';
            submissionFormValidations.socEvergreenContent = submissionForm.socEvergreenContent !== 'Y' ? REQUIRED_FIELD : '';

            submissionFormValidations.socApprovalDate = !submissionForm.socApprovalDate ? REQUIRED_FIELD : '';

            submissionFormValidations.socPostedEng = !submissionForm.socPostedEng ? REQUIRED_FIELD : '';
            submissionFormValidations.socPostedPhoto = !submissionForm.socPostedPhoto ? REQUIRED_FIELD : '';
            submissionFormValidations.socReviewInfluencers = !submissionForm.socReviewInfluencers ? REQUIRED_FIELD : '';
        };

        watch(
            () => [
                submissionForm.socCopyrightCheck,
                submissionForm.socCharCheck,
                submissionForm.socLogoCheck,
                submissionForm.socSpellCheck,
                submissionForm.socApprovalDate,
                submissionForm.socPostedEng,
                submissionForm.socPostedPhoto,
                submissionForm.socReviewInfluencers,
                submissionForm.socEvergreenContent
            ],
            () => {
                if (submissionForm.materialsSupplied === SOCIAL_MEDIA_POST_METARIAL) {
                    validateSocialFields();
                }
            },
            {
                deep: true,
                immediate: true
            }
        );

        watch(
            () => submissionForm.materialsSupplied,
            () => {
                if (submissionForm.materialsSupplied === SOCIAL_MEDIA_POST_METARIAL) {
                    validateSocialFields();
                } else {
                    submissionFormValidations.socCopyrightCheck = '';
                    submissionFormValidations.socCharCheck = '';
                    submissionFormValidations.socLogoCheck = '';
                    submissionFormValidations.socSpellCheck = '';
                    submissionFormValidations.socApprovalDate = '';
                    submissionFormValidations.socPostedEng = '';
                    submissionFormValidations.socPostedPhoto = '';
                    submissionFormValidations.socReviewInfluencers = '';
                    submissionFormValidations.socEvergreenContent = '';
                }
            }
        );

        const yesNoOptions = ref([
            'Yes',
            'No'
        ]);

        const copyTemplateBtnClicked = () => {
            window.open('https://digitalcontent.marvel.com/hc/en-us/articles/360042653751-Copy-Template', '_blank');
        };

        const influencerTemplateBtnClicked = () => {
            window.open('https://digitalcontent.marvel.com/hc/en-us/articles/360042224232-Influencer-Template', '_blank');
        };

        const { userRoles } = useUserAuth();
        const disableSocialReviewFieldState = computed(() => {
            if (['NEW', 'DRAFT'].includes(submissionFormMode.value) || [LICENSEE_STEP].includes(submissionForm.currentStepName) || [BA_ADMIN_ROLE].some(role => userRoles.value.includes(role))) {
                return EDITABLE;
            }
            return VIEW_ONLY;
        });

        return {
            submissionForm,
            submissionFormValidations,
            submissionFormMode,
            // dropdowns Options
            yesNoOptions,
            // links
            copyTemplateBtnClicked,
            influencerTemplateBtnClicked,
            LICENSEE_STEP,
            disableSocialReviewFieldState,
            VIEW_ONLY
        };
    }
};
</script>
